<template>
  <div>
    <div v-if="checkAuthAbility('branch', 'API.Admin.Branch')">
      <b-card no-body>
        <div class="mx-2 mt-2 mb-50">
          <b-row>
            <b-col
              cols="12"
              md="8"
              sm="6"
            >
              <h4 class="mb-0">
                分站列表
              </h4>
            </b-col>

            <b-col
              cols="12"
              md="4"
              sm="6"
            >
              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-if="checkAuthAbility('branch', 'API.Admin.Branch.Create')"
                  v-b-modal.add-modal
                  v-b-tooltip.hover.v-secondary
                  title="新增"
                  class="d-flex align-items-center actions-link-btn"
                >
                  <b-img
                    src="/dashboard/admin/images/table/plus.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="refetchTable"
                >
                  <b-img
                    src="/dashboard/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="列表顯示"
                  class="d-flex align-items-center actions-link-btn ml-25"
                >
                  <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <b-img
                        src="/dashboard/admin/images/table/setting.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </template>

                    <b-dropdown-form>
                      <b-form-group>
                        <label class="mb-50">
                          列表顯示
                        </label>

                        <b-form-checkbox
                          v-for="column in tableColumnsSearchable"
                          :key="column.key"
                          v-model="column.select"
                          name="table-column"
                          class="mb-1"
                        >
                          {{ column.label }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-dropdown-form>

                  </b-dropdown>
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="創建日期"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  :class="timeRange ? 'actions-link-btn-active' : null"
                  @click="() => { $refs.TableTimeRange.getData(timeRange) }"
                >
                  <b-img
                    src="/dashboard/admin/images/table/calendar.svg"
                    class="actions-link-btn-image"
                    rounded
                  />

                  <TableTimeRange
                    ref="TableTimeRange"
                    table-explorer-id="TableTimeRange"
                    @call-back-data="time => timeRange = time"
                  />
                </div>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 顯示個數/搜尋框 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示個數 -->
            <b-col
              cols="12"
              md="6"
              sm="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
            >
              <!-- <label class="text-nowrap">顯示</label> -->
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="table-perPage-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <!-- 搜尋框 -->
            <b-col
              cols="12"
              md="6"
              sm="8"
            >
              <!-- 搜尋框 -->
              <div class="w-100 mr-1">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchQuery"
                    debounce="500"
                    class="d-inline-block"
                    placeholder="搜尋..."
                  />
                  <b-input-group-append
                    v-if="searchQuery"
                    is-text
                  >
                    <feather-icon
                      icon="XIcon"
                      class="cursor-pointer text-muted"
                      @click="searchQuery = null"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 列表 -->
        <b-table
          ref="refDataListTable"
          :items="getBranchListData"
          :fields="tableColumnsFilter"
          responsive
          striped
          hover
          primary-key="id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <!-- 忙碌中 -->
          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="tableColumnsFilter.length"
              :table-props="{ bordered: true, striped: true }"
            />
          </template>

          <!-- 查無資料 -->
          <template #empty>
            <div class="text-center my-5 animate__animated animate__fadeIn">
              <b-img
                :src="$store.state.app.themeImages.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
              />
            </div>
          </template>

          <!-- 欄位: 編號 -->
          <template #cell(id)="data">
            <div class="table-col">
              <span>{{ data.item.id }}</span>
            </div>
          </template>

          <!-- 欄位: 照片  -->
          <template #cell(icon)="data">
            <div class="table-title">
              <div
                v-if="data.item.icon"
                class="table-title-image"
                @click="showImgs(data.item.icon)"
              >
                <VueLoadImage
                  :use-loading="true"
                  :use-failed="true"
                  :blank-height="'30px'"
                  :loading-width="30"
                  :loading-height="30"
                  :failed-width="100"
                >
                  <b-img
                    slot="image"
                    :src="data.item.icon"
                    class="title-image"
                    alt="分站LOGO"
                  />
                  <!-- ? data.item.image : $store.state.app.themeImages.emptyImg -->
                </VueLoadImage>
              </div>

              <span
                v-else
                class="text-muted"
              >尚未設定</span>
            </div>
          </template>

          <!-- 欄位: 分站名稱 -->
          <!-- <template #cell(branch)="data">
            <b-link
              class="font-weight-bold d-block text-nowrap item-description"
              :to="{ name: 'admin-branch-view', params: { id: data.item.id } }"
            >
              {{ data.item.branch }}
            </b-link>
          </template> -->

          <!-- 欄位: 分站名稱 -->
          <template #cell(branch)="data">
            <div class="d-flex align-items-center">
              <div class="ml-50 w-100">
                <b-link
                  class="font-weight-bold d-block text-nowrap show-text item-description"
                  :class="data.item.name ? 'text-primary' : 'text-secondary'"
                  :to="{ name: 'admin-branch-view', params: { id: data.item.id } }"
                >
                  <!-- @click="onSubmitUpdate(data.item)" -->
                  {{ data.item.name ? data.item.name : '尚未設定' }}
                </b-link>

                <!-- <b-link
                  v-else
                  class="font-weight-bold d-block text-nowrap show-text item-description"
                  :class="data.item.name ? 'text-primary' : 'text-secondary'"
                >
                  {{ data.item.name ? data.item.name : '尚未設定' }}
                </b-link> -->

                <small class="text-muted">
                  <div class="email-text">{{ data.item.branch }}</div>
                </small>
              </div>
            </div>
          </template>

          <!-- 欄位: 說明 -->
          <template #cell(introduce)="data">
            <div
              class="table-col item-description"
              @click="onSubmitShowDescription(data.item)"
            >
              {{ data.item.introduce ? data.item.introduce : '---' }}
            </div>
          </template>

          <!-- 欄位: 信箱驗證 -->
          <template #cell(mail_valid_switch)="data">
            <div class="table-col">
              <b-badge
                :variant="ui.switchStateList[1][data.item.mail_valid_switch]"
                @click="onSubmitEdit(data.item)"
              >
                {{ ui.switchStateList[0][data.item.mail_valid_switch] }}
              </b-badge>
            </div>
          </template>

          <!-- 欄位: 電話驗證 -->
          <template #cell(phone_valid_switch)="data">
            <div class="table-col">
              <b-badge
                :variant="ui.switchStateList[1][data.item.phone_valid_switch]"
                @click="onSubmitEdit(data.item)"
              >
                {{ ui.switchStateList[0][data.item.phone_valid_switch] }}
              </b-badge>
            </div>
          </template>

          <!-- 欄位: 會員總數 -->
          <template #cell(customer_count)="data">
            <div class="table-col text-nowrap">
              {{ data.item.customer_count ? `${parseInt(data.item.customer_count, 10).toLocaleString()}` : '---' }}
            </div>
          </template>

          <!-- 欄位: 身分驗證 -->
          <template #cell(kyc_switch)="data">
            <div class="table-col">
              <b-badge
                :variant="ui.switchStateList[1][data.item.kyc_switch]"
                @click="onSubmitEdit(data.item)"
              >
                {{ ui.switchStateList[0][data.item.kyc_switch] }}
              </b-badge>
            </div>
          </template>

          <!-- 欄位: 新增時間 -->
          <template #cell(created_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.created_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.created_at).format('HH:mm')}`"
                >
                  {{ updateOnline(data.item.created_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 動作 -->
          <template #cell(actions)="data">
            <!-- ="data" -->
            <div class="d-flex mb-50">
              <div
                v-if="checkAuthAbility('branch', 'API.Admin.Branch.Update')"
                class="actions-link-btn mr-25"
                @click="onSubmitEdit(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="編輯"
                  src="/dashboard/admin/images/table/edit.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                class="actions-link-btn"
              >
                <b-link
                  :to="{ name: 'admin-branch-view', params: { id: data.item.id } }"
                >
                  <b-img
                    v-b-tooltip.hover.focus.v-secondary
                    title="分站詳情"
                    src="/dashboard/admin/images/table/menu.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </b-link>
              </div>
            </div>
          </template>
        </b-table>

        <!-- 顯示頁數/分頁 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示頁數 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
            </b-col>

            <!-- 分頁 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalNum"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

      </b-card>

      <!-- 新增分站 -->
      <add-modal
        @refetch-data="refetchTable"
      />

      <!-- 編輯分站 -->
      <edit-modal
        v-if="selected"
        ref="editModal"
        :branch-data-item="selected"
        @refetch-data="refetchTable"
      />

      <b-modal
        v-if="selected"
        id="introduce-modal"
        centered
        hide-footer
      >
        <template #modal-title>
          <h4 class="m-0">
            分站說明
          </h4>
        </template>

        <div>
          <div class="introduce-title text-primary">
            {{ selected.name }}
          </div>

          <div class="introduce-content">{{ selected.introduce }}
          </div>
        </div>
      </b-modal>
    </div>

    <div v-else>
      <b-alert
        variant="danger"
        :show="true"
      >
        <h4 class="alert-heading">
          權限不足
        </h4>
        <div class="alert-body">
          返回
          <b-link
            class="alert-link"
            :to="{ name: 'admin-home'}"
          >
            後台首頁
          </b-link>
        </div>
      </b-alert>
    </div>

    <!-- {{ authAbility }} -->
  </div>
</template>
<script>
// API
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'

// UI
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BImg, BLink, BBadge, BAlert,
  BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
  BInputGroupAppend, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import router from '@/router'
import store from '@/store'

// Component
import { useBranchList, useBranchSetting } from '../useBranch'
import AddModal from './BranchListAddModal.vue'
import EditModal from './BranchListEditModal.vue'
import TableTimeRange from '@/layouts/components/Table/TimeRange.vue'
import VueLoadImage from '@/layouts/components/ImageLoader/VueLoadImage.vue'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import useStoreModule from '../useStoreModule'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BImg,
    BLink,
    BBadge,
    BFormInput,
    BTable,
    BModal,
    BPagination,
    BDropdown,
    // BDropdownItem,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,

    vSelect,
    AddModal,
    EditModal,
    TableTimeRange,
    VueLoadImage,
    // AuthModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      selected: null,
      title: router.currentRoute.meta.pageTitle,
    }
  },
  computed: {
    tableColumnsFilter() {
      // if (this.authAbility.user !== 'write') {
      //   return this.tableColumns
      //     .filter(f => f.select && f.key !== 'actions')
      // }
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      // if (this.authAbility.user !== 'write') {
      //   return this.tableColumns
      //     .filter(f => f.searchable && f.key !== 'actions')
      // }
      return this.tableColumns
        .filter(f => f.searchable)
    },
    // authAbility() {
    //   if (this.$store.state.app.authPower && this.$store.state.app.authPower.branch) return this.$store.state.app.authPower.branch || []
    //   return []
    // },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (觸發)圖片顯示
    showImgs(iamge) {
      this.$viewerApi({
        images: [iamge],
        options: {
          navbar: false,
          movable: false,
        },
      })
    },

    // (觸發)顯示分站說明
    onSubmitShowDescription(item) {
      if (!item.introduce) return
      this.selected = item
      setTimeout(() => { this.$bvModal.show('introduce-modal') }, 200)
    },

    // (觸發)編輯分站
    onSubmitEdit(item) {
      const auth = this.checkAuthAbility('branch', 'API.Admin.Branch.Update')
      if (!auth) return
      this.selected = item
      setTimeout(() => { this.$refs.editModal.getData() }, 200)
    },
  },
  setup() {
    // 註冊模組
    const BRANCH_ADMIN_STORE_MODULE_NAME = 'admin-branch'

    if (!store.hasModule(BRANCH_ADMIN_STORE_MODULE_NAME)) store.registerModule(BRANCH_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(BRANCH_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(BRANCH_ADMIN_STORE_MODULE_NAME)
    })

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      timeRange,

      setBranchDelete,
      getBranchListData,
    } = useBranchList()

    const {
      ui,
      statusOptions,
      refonlineTime,
      onlineTime,
      updateOnline,
      checkAuthAbility,
    } = useBranchSetting()

    const {
      useAlertToast,
    } = useAlert()

    return {
      ui,
      useAlertToast,
      statusOptions,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      timeRange,

      setBranchDelete,
      getBranchListData,
      refonlineTime,
      onlineTime,
      updateOnline,
      checkAuthAbility,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.table-title-image {
  .title-image {
    max-width: 100px;
    max-height: 40px;
    text-align: center;
  }
}
.item-description {
  // background-color: red;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  // font-size: 1.4rem;
}

.introduce-title {
  font-size: 18px;
  margin-bottom: 8px;
}

.introduce-content {
  white-space: pre-wrap;
}
</style>
